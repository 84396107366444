import { sortByDate } from "@rocket-resume/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useResumes } from "@/client/services/resume";
import { BaseCard } from "./_components/base-card";
import { ResumeCard } from "./_components/resume-card";
import { useEffect, useState } from "react";
import { useResumePreview } from "@/client/services/resume/preview";
import { ResumePreview } from "./_components/resume-preview";


export const GridView = () => {
  const { resumes, loading, refetch } = useResumes();
  const [hasRefetched, setHasRefetched] = useState<Boolean>(false);
  const [resumePreview, setResumePreview] = useState<Boolean>(false);
  const [selectedRId, setSelectedRId] = useState<string | null>(null);
  const { url } = useResumePreview(selectedRId ? selectedRId : '');


  useEffect(() => {
    if (resumes && !hasRefetched) {
      refetch();
      setHasRefetched(true);
      if (resumes.length > 0 && !selectedRId) {
        setSelectedRId(resumes[0].id);
        setResumePreview(true)
      }
    }
  }, [resumes, refetch, hasRefetched]);

  const handlePreview = (template: string) => {
    console.log("template",template);
    setResumePreview(true)
    setSelectedRId(template); 
  };

  const handleClosePreview = () =>{
    setSelectedRId(null)
    setResumePreview(false)
  }

  return (
    <div className="flex justify-between h-[86vh]">
      <div className="w-[100%] md:w-[28%] xl:w-[35%] overflow-y-auto scrollbar-hide p-2">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 xl:grid-cols-2">
          {loading &&
            Array.from({ length: 4 }).map((_, i) => (
              <div
                key={i}
                className="duration-300 animate-in fade-in"
                style={{ animationFillMode: "backwards", animationDelay: `${i * 300}ms` }}
              >
                <BaseCard />
              </div>
            ))}

          {resumes && resumes?.length > 0 && (
            <AnimatePresence>
              {resumes
                .sort((a, b) => sortByDate(a, b, "updatedAt"))
                .map((resume, index) => (
                  <motion.div
                    key={resume.id}
                    layout
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: (index + 2) * 0.1 } }}
                    exit={{ opacity: 0, filter: "blur(8px)", transition: { duration: 0.5 } }}
                  >
                    <ResumeCard resume={resume} handleClosePreview={handleClosePreview} handlePreview={handlePreview} isSelected={resume.id === selectedRId} />
                  </motion.div>
                ))}
            </AnimatePresence>
          )}
        </div>
      </div>
      {
        resumePreview && url && 
        <ResumePreview handleClosePreview={handleClosePreview} url={url} selectedRId={selectedRId} />
      }
    </div>
  );
};
